

import Vue from "vue"

export default Vue.extend({
	name: "OrganizationOverview",
	components: {
	},
	props: {
		msg: {
			type: String,
		},
    },
	data(): {
		loading: boolean
        headers: any[]
        invitations: any[]
	} {
		return {
			loading: false,
            headers: [
          {
            text: 'Organization Name',
            align: 'start',
            sortable: false,
            value: 'orgname',
          },
          { text: 'Organization number', value: 'orgnumber' },
          
          { text: 'E-mail', value: 'email' },
          { text: 'Phone', value: 'phone' },
        ],
        invitations: []
		}
	},
    mounted() {
        const formdata = this.$vStore.state.context.createTenantFormData
        if(formdata.companyName && formdata.email && formdata.phoneNumber){
            const inv = {
                orgname: formdata.companyName,
                orgnumber: formdata.businessRegistryId,
                email: formdata.email,
                phone: formdata.phoneNumber
            }
            this.invitations.push(inv)
        }
    },
    computed: {
        getInvitations(){
            const formdata = this.$vStore.state.context.createTenantFormData
            const invitations = []
            let inv = {
                    orgname: "formdata.companyName",
                    orgnumber: "formdata.businessRegistryId",
                    email: "formdata.email",
                    phone: "formdata.phoneNumber"
                }
            if(formdata.companyName && formdata.email && formdata.phoneNumber){
                inv = {
                    orgname: formdata.companyName,
                    orgnumber: formdata.businessRegistryId,
                    email: formdata.email,
                    phone: formdata.phoneNumber
                }
                
            }
            invitations.push(inv)
            return invitations
        }
    },
	methods: {

	},

})
